import {defineStore} from 'pinia';
import {CategoryItem, SearchType} from 'src/apps/search/types';
import deepmerge from 'deepmerge';
import {getCityFromNominatim} from 'src/etc/utils';
import {DEFAULT_SEARCH_TYPE, SEARCH_TYPES} from 'src/apps/search/vars';
import Adverts from 'src/api/adverts';
import axios from 'axios';
import Business from 'src/api/business';
import {getChipLabelExtraFigureKeys} from 'src/etc/helper';
import {SearchLocation} from 'src/apps/search/models';
import {useSearchTypeCategories} from 'src/apps/search/categories';

interface State {
  searchResults: any,
  _searchType: SearchType | undefined
  searchLocation?: any,
  searchTerm?: string,
  searchLoading: boolean,
  activeCategory: CategoryItem | undefined
  filterData: any,
  searchScrollPos?: number,

  mapSearchResults: any,
  mapState: {
    coordinates?: [number, number],
    zoom?: number,
  },
  itemsVisible: object
}


const fetchFunctionMapping = {
  'explore': undefined,
  'vehicle': Adverts.getAdverts,
  'dealer': Business.fetchBusinessPublicList,
  'market': undefined,
  // 'market': Market.fetchItems,
}

const CancelToken = axios.CancelToken;
let cancel


export const useSearchStore = defineStore('search', {
  state: (): State => ({
    searchResults: {
      vehicle: {
        items: [],
        count: 0,
        page: 0,
        minPrice: 0,
        maxPrice: 1_000_000,
        minMileage: 0,
        maxMileage: 1_000_000,
        totalPages: undefined,
      },
      dealer: {
        items: [],
        count: 0,
        page: 0,
        totalPages: undefined,
      },
      market: {
        items: [],
        count: 0,
        page: 0,
        totalPages: undefined,
      },
      explore: {
        items: [],
        count: 0,
        page: 0,
        totalPages: undefined,
      },
    },
    searchLoading: true,
    _searchType: undefined,
    activeCategory: undefined,
    searchLocation: undefined,
    searchTerm: undefined,
    filterData: {},
    searchScrollPos: undefined,

    mapSearchResults: undefined,
    mapState: {},

    itemsVisible: {}
  }),

  getters: {
    hasMorePages: (state) => {
      const result = state.searchResults[state._searchType ?? DEFAULT_SEARCH_TYPE]
      return result.page < result.totalPages
    },

    searchResultCount: (state): number => {
      const results = state.searchResults[state._searchType ?? DEFAULT_SEARCH_TYPE]
      return results.count ?? results.items?.length ?? 0
    },

    searchType(state): SearchType {
      return state._searchType ?? DEFAULT_SEARCH_TYPE
    },

    getItems(state) {
      return state.searchResults[state._searchType ?? DEFAULT_SEARCH_TYPE].items
    }

  },

  actions: {
    updateMapState(coordinates, zoom) {
      this.mapState = {coordinates, zoom}
    },

    updateScrollPos(pos: number) {
      this.searchScrollPos = pos
    },

    resetScrollPos() {
      this.searchScrollPos = undefined
    },

    clearMapSearch() {
      this.mapSearchResults = undefined
    },

    updateSearchType(searchType: SearchType | undefined) {
      if (searchType && SEARCH_TYPES.includes(searchType)) {
        this._searchType = searchType
      } else {
        console.warn('Search type not found', searchType)
        this._searchType = DEFAULT_SEARCH_TYPE
      }

      // reset results instantly
      this.resetFilterData()
      this.resetResults()
      this.activeCategory = undefined
    },

    async getMapSearchResults(force = false) {
      if (!force && this.mapSearchResults) {
        this.searchLoading = false
        return this.mapSearchResults
      }
      this.searchLoading = true

      const resp = await Adverts.getMapList(this.filterData)
      this.mapSearchResults = resp.data
      this.searchLoading = false
      return this.mapSearchResults
    },

    setSearchLocation(location: SearchLocation) {
      if (!location) return
      this.updateFilterData({
        l: `${location?.address?.postcode} ${getCityFromNominatim(location?.address)}`.trim(),
        r: location.radius
      }, true)
      this.searchLocation = location
    },

    resetFilterData() {
      // dont reset following
      const {l, s, r} = this.filterData
      this.updateFilterData({l, s, r}, false)
    },

    setActiveCategory(category: CategoryItem | undefined) {
      // reset results instantly
      this.searchResults[this.searchType].items = []

      const {getSearchTypeCategory} = useSearchTypeCategories()

      const oldCategory = this.activeCategory
      const oldCatFilter = getSearchTypeCategory(this.searchType, oldCategory?.name ?? 'all').filterData ?? {}

      const oldKeys = Object.keys(oldCatFilter)
      const cleanOld = {}

      this.activeCategory = category
      const _catFilter = getSearchTypeCategory(this.searchType, category?.name ?? 'all').filterData

      // empty old category filters
      oldKeys.forEach((val) => {
        cleanOld[val] = undefined
      })

      this.updateFilterData({
        ...this.filterData,
        ...cleanOld,
        ..._catFilter
      }, false)
    },

    updateFilterData(data: object, merge = true) {
      this.resetScrollPos()
      this.clearMapSearch()
      if (!merge) {
        this.filterData = data
        return
      }

      this.filterData = deepmerge(this.filterData, data)
    },

    resetResults(searchType: SearchType | undefined = undefined) {
      this.itemsVisible = {}
      const result = this.searchResults[searchType ?? this.searchType]
      result.page = 0
      result.count = 0
      result.totalPages = undefined
      result.items = []

      this.resetScrollPos()
      this.clearMapSearch()
    },

    setItemVisibility(index, isVisible) {
      this.itemsVisible = {...this.itemsVisible, [index]: isVisible};
    },

    _fetchData(force = false) {
      const fetchFn = fetchFunctionMapping[this.searchType]
      if (!fetchFn) {
        this.searchLoading = false
        return Promise.resolve({})
      }
      console.debug('SearchStore', 'fetching data', this.searchType)

      if (!(fetchFn instanceof Function)) {
        this.searchLoading = false
        return Promise.reject('fetchFn parameter is not a function')
      }
      this.searchLoading = true

      if (cancel) {
        this.searchLoading = false
        cancel();
      }

      return this.fetchResponse(
        fetchFn,
        {...this.filterData},
        new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        force
      ).catch((err) => {
        if (axios.isCancel(err)) {
          console.debug('canceled');
        } else {
          console.error(err);
        }

        return err
      }).finally(() => {
        this.searchLoading = false;
        cancel = undefined
      });
    },

    toggleExtraFilter(value: string) {
      if (!this.filterData.vehicle__extras__slug) {
        this.filterData.vehicle__extras__slug = []
      }

      if (this.filterData.vehicle__extras__slug.includes(value)) {
        const i = this.filterData.vehicle__extras__slug.indexOf(value)
        this.filterData.vehicle__extras__slug.splice(i, 1)

        if (getChipLabelExtraFigureKeys(value) !== undefined) {
          const keys = getChipLabelExtraFigureKeys(value)
          if (!keys) return
          this.filterData[keys.minKey] = undefined
          this.filterData[keys.maxKey] = undefined
        }

      } else {
        this.filterData.vehicle__extras__slug.push(value)
      }
    },

    async fetchResponse(
      axiosFn,
      params: undefined | any = undefined,
      cancel,
      initial = false
    ) {
      if (initial) {
        this.resetResults(this.searchType)
      }

      const result = this.searchResults[this.searchType]

      if (result.page >= result.totalPages) {
        console.debug('fetchResponse: reached end of pages')
        return
      }
      this.searchLoading = true
      try {
        result.page += 1
        const response = await axiosFn({...params, page: result.page})
        result.items = [...result.items, ...response.data?.results]
        result.totalPages = response.data.pages
        result.count = response.data.count

        if (this.searchType === 'vehicle') {
          result.minPrice = response.data.min_price
          result.maxPrice = response.data.max_price
          result.minMileage = response.data.min_mileage
          result.maxMileage = response.data.max_mileage
        }

        if (this.searchType === 'market') {
          result.minPrice = response.data.min_price
          result.maxPrice = response.data.max_price
        }

        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        this.searchLoading = false
      }
    }
  }
})
